import React from 'react';

import SEO from '../components/SEO';
import Anchor from '../components/shared/Anchor';
import Paragraph from '../components/shared/Paragraph';

export default () => (
  <>
    <SEO title="Experience" />
    <Paragraph>
      I am currently pursuing a Bachelor’s degree in Computer Science from{' '}
      <em>Faculty of Computer Science & Engineering</em> in Skopje, Macedonia.
      During my studies (December 2018), I have interned at{' '}
      <Anchor href="https://www.intertec.io/">Intertec.io</Anchor> as a Web
      Developer but mostly doing Frontend. The internship really helped me find
      out what I am good at. I worked on a project with Angular, NodeJS and
      Google AMP.
    </Paragraph>
    <Paragraph>
      I started working as a web developer around April 2019 at{' '}
      <Anchor href="https://www.intertec.io/">Intertec.io</Anchor>. I've made
      web applications and responsive websites for{' '}
      <strong>worldwide clients</strong>. I worked with technologies such as
      ReactJS, GraphQL, NestJS and MySQL. I also have experience with SCSS,
      Bootstrap and Material UI.
    </Paragraph>
    <Paragraph>
      I have given a lecture and have been a mentor at a workshop organized by
      the{' '}
      <Anchor href="https://www.finki.ukim.mk/en">
        Faculty of Computer Science & Engineering
      </Anchor>{' '}
      for students who are new to frontend and ReactJS.
    </Paragraph>
    <Paragraph>
      Nowadays I am still working at Intertec as a web developer and do some
      freelance from time to time.
    </Paragraph>
  </>
);
